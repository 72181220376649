import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BaseLink from '../BaseLink/BaseLink';
import SectionTitle from '../SectionTitle/SectionTitle';
import WorldMap from '../WorldMap/WorldMap';
import * as styles from './Team.module.scss';

export default function Team() {
  let countriesTemp = [];
  let timezoneTemp = [];
  const {
    allTeamMember: { nodes: developers },
    worldMapImg,
  } = useStaticQuery(query);
  developers.forEach((member) => {
    timezoneTemp.push(member.timezone);
    if (member.location.includes('USA')) {
      countriesTemp.push('USA');
    } else {
      countriesTemp.push(member.location.split(',')[1]);
    }
  });
  const countries = Array.from(new Set(countriesTemp));
  const timezone = Array.from(new Set(timezoneTemp));
  const teamSummary = [
    { count: developers.length, text: 'teammates' },
    { count: countries.length, text: 'countries' },
    { count: timezone.length, text: 'time zones' },
  ];

  return (
    <section id="the-team" className={styles.team}>
      <SectionTitle title="The Team">
        <p>
          At Iterative, we build <BaseLink href="https://dvc.org">DVC</BaseLink>
          , <BaseLink href="https://cml.dev">CML</BaseLink>, and other developer
          tools for machine learning. We're a well-funded, remote-first team on
          a mission to solve the complexity of managing datasets, ML
          infrastructure, and ML models lifecycle management.
        </p>
      </SectionTitle>
      <div className={styles.team__mapContainer}>
        <WorldMap worldMapImg={worldMapImg} developers={developers} />
        <ul className={styles.team__list}>
          {teamSummary.map((item, i) => (
            <li className={styles.team__item} key={i}>
              <h4 className={styles.team__itemCount}>{item.count}</h4>
              <p className={styles.team__itemText}>{item.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

const query = graphql`
  query getTeam {
    worldMapImg: file(relativePath: { regex: "/world-map.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    allTeamMember {
      totalCount
      nodes {
        topPosition
        rightPosition
        regions
        markerOffsetX
        markerOffsetY
        title
        job
        location
        image {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1, width: 150, placeholder: NONE)
          }
        }
        timezone
        text
        globeLink
        githubLink
        twitterLink
        linkedinLink
      }
    }
  }
`;
