import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import SectionTitle from '../SectionTitle/SectionTitle';
import DeveloperInfo from '../DeveloperInfo/DeveloperInfo';
import { leadership } from './Leadership.module.scss';

const leadershipMembers = [
  {
    image: 'dmitryPetrovAvatar',
    color: '#13ADC7',
    name: 'Dmitry Petrov',
    job: 'Co-Founder and CEO',
    description:
      'Creator of DVC. Ex-Data Scientist at Microsoft. PhD in Computer Science.',
  },
  {
    image: 'ivanShchekleinAvatar',
    color: '#945DD6',
    name: 'Ivan Shcheklein',
    job: 'Co-Founder and CTO',
    description:
      'Ex co-founder of TweetedTimes - acquired by Yandex. Contributor to Sedna Database.',
  },
  {
    image: 'jenyDeFigueiredoAvatar',
    color: '#F46737',
    name: 'Jeny De Figueiredo',
    job: 'Community Manager',
    description:
      'Data Enthusiast. Former Olympian. Passionate about learning, connecting people and ideas.',
  },
  {
    image: 'ruslanKuprieievAvatar',
    color: '#13ADC7',
    name: 'Ruslan Kuprieiev',
    job: 'Engineer',
    description:
      'DVC maintainer. Founding engineer at Iterative. Ex Parallels.',
  },
  {
    image: 'jordanWeberAvatar',
    color: '#945DD6',
    name: 'Jordan Weber',
    job: 'Chief of Staff',
    description: 'Chief of Staff formally in FinTech and VC, Cornell Grad.',
  },
];

export function PresentationalLeadership({ developers }) {
  return (
    <section className={leadership}>
      <SectionTitle title="Leadership" />
      <DeveloperInfo developers={developers} />
    </section>
  );
}

export default function Leadership() {
  const images = useStaticQuery(query);
  const leadershipWithLoadedImages = leadershipMembers.map((leader) => ({
    ...leader,
    image: getSrc(images[leader.image]),
  }));

  return <PresentationalLeadership developers={leadershipWithLoadedImages} />;
}

const query = graphql`
  query getLeadershipImages {
    dmitryPetrovAvatar: file(
      relativePath: { regex: "/dmitry-petrov-avatar.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 150, aspectRatio: 1)
      }
    }
    ivanShchekleinAvatar: file(
      relativePath: { regex: "/ivan-shcheklein-avatar.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 150, aspectRatio: 1)
      }
    }
    jenyDeFigueiredoAvatar: file(
      relativePath: { regex: "/jeny-de-figueiredo-avatar.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 150, aspectRatio: 1)
      }
    }
    ruslanKuprieievAvatar: file(
      relativePath: { regex: "/ruslan-kuprieiev-avatar.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 150, aspectRatio: 1)
      }
    }
    jordanWeberAvatar: file(
      relativePath: { regex: "/jordan-weber-avatar.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 150, aspectRatio: 1)
      }
    }
  }
`;
