import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getImage } from 'gatsby-plugin-image';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import locationIcon from '../../images/icn-location.svg';
import { logEvent } from '../../utils/plausible';
import * as styles from './MapMarker.module.scss';

export default function MapMarker({
  onClick,
  top,
  right,
  image,
  name,
  job,
  location,
  className,
  region,
}) {
  const [isMarkerRightCutoff, setIsMarkerRightCutoff] = useState(false);

  function checkForTooltipCutoff(e) {
    const TOOLTIP_WIDTH = 155;
    const rect = e.target.getBoundingClientRect();
    setIsMarkerRightCutoff(
      rect.x + rect.width + TOOLTIP_WIDTH >=
        document.documentElement.clientWidth
    );
  }

  return (
    <span
      className={cn(
        styles.marker,
        className,
        styles[`marker_region_${region}`]
      )}
      style={{
        top,
        right,
      }}
      onMouseEnter={checkForTooltipCutoff}
    >
      <button
        onClick={() => {
          logEvent('World Map', { Button: 'region' });
          onClick();
        }}
        className={styles.marker__btn}
      >
        <Image
          className={styles.marker__img}
          gatsbyImageData={getImage(image)}
          alt={name}
        />
      </button>
      <div
        className={cn(
          styles.marker__tooltip,
          isMarkerRightCutoff && styles.marker__tooltip_pos_left
        )}
      >
        <h3 className={styles.marker__name}>{name}</h3>
        <p className={styles.marker__job}>{job}</p>
        <p className={styles.marker__location}>
          <Icon icon={locationIcon} widthPixelSizes={[8, 8, 8]} />
          {location}
        </p>
        <p className={styles.marker__showMore}>Click to show more</p>
      </div>
    </span>
  );
}

MapMarker.propTypes = {
  top: PropTypes.string,
  right: PropTypes.string,
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  className: PropTypes.string,
  region: PropTypes.oneOf([
    'gl',
    'ak',
    'can',
    'canEast',
    'canWest',
    'us',
    'usWest',
    'usEast',
    'eu',
    'euWest',
    'euEast',
    'as',
    'asNorth',
    'asWest',
    'asSouth',
    'asEast',
    'asSoutheast',
    'ca',
    'af',
    'afNorth',
    'afSouth',
    'oc',
    'sa',
    'caSa',
  ]),
  onClick: PropTypes.func.isRequired,
};
