import React from 'react';
import PropTypes from 'prop-types';

import Popup from '../Popup/Popup';
import Marker from '../MapMarker/MapMarker';
import Icon from '../Icon/Icon';
import closeIcon from '../../images/icn-close.svg';
import * as styles from './MapRegionPopup.module.scss';

export function PresentationalMapRegionPopup({
  members,
  close,
  onMarkerClick,
}) {
  const places = [
    ...new Set(members.map(({ location }) => location.split(', ')[1]).sort()),
  ];
  const formattedMembers = members.map((member) => ({
    ...member,
    place: member.location.split(', ')[1],
  }));

  return (
    <div className={styles.regionPopup} tabIndex={0}>
      <button className={styles.regionPopup__closeBtn} onClick={close}>
        <Icon icon={closeIcon} widthPixelSizes={[16, 16, 16]} />
        <span className={styles.regionPopup__visuallyHiddenEl}>
          Close Popup
        </span>
      </button>
      {places.map((place, i) => {
        const markers = formattedMembers
          .filter((member) => member.place === place)
          .sort(({ place: place1 }, { place: place2 }) => {
            if (place1 < place2) {
              return -1;
            }
            if (place1 > place2) {
              return 1;
            }
            return 0;
          })
          .map((member, i) => (
            <li key={i}>
              <Marker
                location={member.location}
                name={member.title}
                job={member.job}
                image={member.image}
                className={styles.place__marker}
                onClick={() => onMarkerClick(member)}
              />
            </li>
          ));
        return (
          <div className={styles.place} key={i}>
            <h3 className={styles.place__title}>{place}</h3>
            <ul className={styles.place__markers}>{markers}</ul>
          </div>
        );
      })}
    </div>
  );
}

export default function MapRegionPopup({
  members,
  isVisible,
  onClose,
  onMarkerClick,
}) {
  return (
    <Popup
      isVisible={isVisible}
      onClose={onClose}
      ariaLabel="Select a Team Tember for More Info"
      getContents={(close) => (
        <PresentationalMapRegionPopup
          close={close}
          members={members}
          onMarkerClick={onMarkerClick}
        />
      )}
    />
  );
}

MapRegionPopup.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      job: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    })
  ).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
