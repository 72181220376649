import React from 'react';
import Avatar from '../Avatar/Avatar';
import {
  developerInfo,
  developerInfo__listItem,
  developerInfo__textContainer,
  developerInfo__name,
  developerInfo__job,
  developerInfo__description,
} from './DeveloperInfo.module.scss';

export default function DeveloperInfo({ developers = [] }) {
  return (
    <ul className={developerInfo}>
      {developers.map((developer, i) => {
        const lastNameInd = developer.name.match(/\w+$/).index;
        return (
          <li key={i} className={developerInfo__listItem}>
            <Avatar
              image={developer.image}
              color={developer.color}
              shape="square"
              id={`leader_${i}`}
            />
            <div className={developerInfo__textContainer}>
              <h4 className={developerInfo__name}>
                <span>{developer.name.slice(0, lastNameInd)}</span>
                <span>{developer.name.slice(lastNameInd)}</span>
              </h4>
              <h5 className={developerInfo__job}>{developer.job}</h5>
              <p className={developerInfo__description}>
                {developer.description}
              </p>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
