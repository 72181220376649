import React from 'react';
import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import PageTitle from '../components/PageTitle/PageTitle';
import TimeLine from '../components/TimeLine/TimeLine';
import Team from '../components/Team/Team';
import LeaderShip from '../components/Leadership/Leadership';
import Career from '../components/Career/Career';
import Footer from '../components/Footer/Footer';
import { times } from '../utils/data';

export default function AboutPage() {
  return (
    <Layout title="About Us">
      <Header>
        <PageTitle
          title="About Us"
          text="Data Science = Highly Iterative Metrics-driven Process With Data and Code"
        />
        <TimeLine times={times} />
      </Header>
      <Team />
      <LeaderShip />
      <Career />
      <Footer />
    </Layout>
  );
}
