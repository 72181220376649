import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getImage, getSrc } from 'gatsby-plugin-image';
import DeveloperCard from '../DeveloperCard/DeveloperCard';
import { defaultDeveloperData } from '../../utils/data';
import Marker from '../MapMarker/MapMarker';
import MarkerGroup from '../MapMarkerGroup/MapMarkerGroup';
import RegionPopup from '../MapRegionPopup/MapRegionPopup';
import Image from '../Image/Image';
import { sortMembersByRegion } from './utils';
import * as styles from './WorldMap.module.scss';

export default function WorldMap({ developers = [], worldMapImg }) {
  const [isDeveloperCardOpen, setIsDeveloperCardOpen] = useState(false);
  const [isRegionPopupOpen, setIsRegionPopupOpen] = useState(false);
  const [currentlySelectedRegion, setCurrentlySelectedRegion] = useState('');
  const [developerCardData, setDeveloperCardData] =
    useState(defaultDeveloperData);
  const { regions, restOfMembers } = sortMembersByRegion(developers);

  const closeRegionPopup = () => {
    setIsRegionPopupOpen(false);
  };
  const closeDeveloperPopup = () => {
    setIsDeveloperCardOpen(false);
    setDeveloperCardData(defaultDeveloperData);
  };
  const backDeveloperPopup = () => {
    setIsDeveloperCardOpen(false);
    setDeveloperCardData(defaultDeveloperData);
    if (currentlySelectedRegion) {
      setIsRegionPopupOpen(true);
    }
  };

  const handleMarkerClick = (developerData) => {
    if (isRegionPopupOpen) closeRegionPopup();
    setDeveloperCardData(developerData);
    setIsDeveloperCardOpen(true);
  };

  const handleMarkerGroupClick = (region) => {
    if (isDeveloperCardOpen) closeDeveloperPopup();
    setCurrentlySelectedRegion(region);
    if (region) setIsRegionPopupOpen(true);
  };

  const getRegions = (regions) => {
    const jsxList = [];
    for (const [region, members] of Object.entries(regions)) {
      if (members.length === 0) continue;
      const jsx = (
        <span key={region}>
          {members.length < 3 &&
          ['caSa', 'us', 'as', 'eu', 'can', 'af', 'oc'].includes(region) ? (
            <>
              <MarkerGroup
                region={region}
                onClick={() => handleMarkerGroupClick(region)}
                members={members}
                mobileOnly
              />
              {members.map((member, i) => {
                return (
                  <Marker
                    key={i}
                    top={member.topPosition}
                    right={member.rightPosition}
                    onClick={() => {
                      setCurrentlySelectedRegion('');
                      handleMarkerClick(member);
                    }}
                    image={member.image}
                    name={member.title}
                    job={member.job}
                    location={member.location}
                    region={region}
                  />
                );
              })}
            </>
          ) : members.length < 3 ? (
            members.map((member, i) => {
              return (
                <Marker
                  key={i}
                  top={member.topPosition}
                  right={member.rightPosition}
                  onClick={() => {
                    setCurrentlySelectedRegion('');
                    handleMarkerClick(member);
                  }}
                  image={member.image}
                  name={member.title}
                  job={member.job}
                  location={member.location}
                  region={region}
                />
              );
            })
          ) : (
            <MarkerGroup
              region={region}
              onClick={() => handleMarkerGroupClick(region)}
              members={members}
            />
          )}
        </span>
      );

      // The map map does not account for regions with two overlapping members.
      // As a temporary solution, we can place these regions into this array:
      if (['can', 'canEast'].includes(region)) {
        const twoMembersJsx = (
          <span key={region}>
            <MarkerGroup
              region={region}
              onClick={() => handleMarkerGroupClick(region)}
              members={members}
            />
          </span>
        );

        jsxList.push(twoMembersJsx);
        continue;
      }

      jsxList.push(jsx);
    }
    return jsxList;
  };

  return (
    <div className={styles.worldmap}>
      <DeveloperCard
        isOpen={isDeveloperCardOpen}
        title={developerCardData.title}
        job={developerCardData.job}
        location={developerCardData.location}
        text={developerCardData.text}
        image={getSrc(developerCardData.image) || ''}
        globeLink={developerCardData.globeLink}
        twitterLink={developerCardData.twitterLink}
        linkedinLink={developerCardData.linkedinLink}
        githubLink={developerCardData.githubLink}
        onClose={closeDeveloperPopup}
        onBack={backDeveloperPopup}
      />
      <RegionPopup
        isVisible={isRegionPopupOpen}
        curRegion={currentlySelectedRegion}
        members={regions[currentlySelectedRegion] || []}
        onMarkerClick={handleMarkerClick}
        onClose={closeRegionPopup}
      />
      <Image
        className={styles.worldmap__img}
        gatsbyImageData={getImage(worldMapImg)}
        alt="a world map with markers, showing the Iterative team"
      />
      {getRegions(regions)}
      {restOfMembers.map((member, i) => {
        return (
          <Marker
            key={i}
            top={member.topPosition}
            right={member.rightPosition}
            onClick={() => {
              setCurrentlySelectedRegion('');
              handleMarkerClick(member);
            }}
            image={member.image}
            name={member.title}
            job={member.job}
            location={member.location}
          />
        );
      })}
    </div>
  );
}

WorldMap.propTypes = {
  developers: PropTypes.arrayOf(
    PropTypes.shape({
      topPosition: PropTypes.string.isRequired,
      rightPosition: PropTypes.string.isRequired,
      markerOffsetX: PropTypes.number,
      markerOffsetY: PropTypes.number,
      title: PropTypes.string.isRequired,
      job: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      timezone: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(PropTypes.string),
      text: PropTypes.string,
      image: PropTypes.object.isRequired,
      globeLink: PropTypes.string,
      twitterLink: PropTypes.string,
      linkedinLink: PropTypes.string,
      githubLink: PropTypes.string,
    })
  ).isRequired,
};
