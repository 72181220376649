import React, { useEffect, useRef, useState } from 'react';
import { getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Image from '../Image/Image';
import { logEvent } from '../../utils/plausible';
import * as styles from './MapMarkerGroup.module.scss';

export default function MapMarkerGroup({
  members,
  region,
  onClick,
  mobileOnly,
}) {
  const [randomMembers, setRandomMembers] = useState([]);
  const [isMarkerRightCutoff, setIsMarkerRightCutoff] = useState(false);
  const markerGroupAmountEl = useRef(null);
  const positionOrder =
    members.length === 1
      ? ['bottom']
      : members.length === 2
      ? ['right', 'bottom']
      : ['left', 'right', 'bottom'];

  useEffect(() => {
    const getRandomMembers = () => {
      const randomIndexes = [];

      while (
        randomIndexes.length !== (members.length < 3 ? members.length : 3)
      ) {
        const index = Math.floor(Math.random() * members.length);
        if (!randomIndexes.includes(index)) {
          randomIndexes.push(index);
        }
      }

      return randomIndexes.map((index) => members[index]);
    };

    if (randomMembers.length === 0) {
      setRandomMembers(getRandomMembers());
    }
  }, [members, setRandomMembers, randomMembers]);

  function checkForTooltipCutoff() {
    const TOOLTIP_WIDTH = 155;
    const rect = markerGroupAmountEl.current.getBoundingClientRect();
    setIsMarkerRightCutoff(
      rect.x + rect.width + TOOLTIP_WIDTH + 20 >=
        document.documentElement.clientWidth
    );
  }

  return (
    <span
      className={cn(
        styles.markerGroup,
        styles[`markerGroup_region_${region}`],
        mobileOnly && styles.markerGroup_mobileOnly,
        members.length === 1 && styles.markerGroup_oneMember,
        members.length === 2 && styles.markerGroup_twoMembers
      )}
    >
      <button
        onClick={() => {
          logEvent('World Map', { Button: 'member' });
          onClick();
        }}
        className={styles.markerGroup__btn}
        onMouseEnter={checkForTooltipCutoff}
      >
        {positionOrder.map((pos, i) => (
          <div
            key={i}
            className={cn(
              styles.markerGroup__marker,
              styles[`markerGroup__markerBg_${pos}`],
              styles.markerGroup__markerBg
            )}
          ></div>
        ))}
        {randomMembers.map((member, i) => (
          <div
            className={cn(
              styles.markerGroup__marker,
              styles[`markerGroup__marker_${positionOrder[i]}`]
            )}
          >
            <Image
              className={styles.markerGroup__img}
              alt={member.title}
              gatsbyImageData={getImage(member.image)}
            />
          </div>
        ))}
        <span ref={markerGroupAmountEl} className={styles.markerGroup__amount}>
          <p>{members.length}</p>
        </span>
      </button>
      <div
        className={cn(
          styles.tooltip,
          isMarkerRightCutoff && styles.tooltip_pos_left
        )}
      >
        <p className={styles.tooltip__amount}>
          {members.length} {members.length === 1 ? 'person' : 'people'}
        </p>
        <p className={styles.tooltip__showMore}>Click to show more</p>
      </div>
    </span>
  );
}

MapMarkerGroup.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    })
  ).isRequired,
  region: PropTypes.oneOf([
    'gl',
    'ak',
    'can',
    'canEast',
    'canWest',
    'us',
    'usWest',
    'usEast',
    'eu',
    'euWest',
    'euEast',
    'as',
    'asNorth',
    'asWest',
    'asSouth',
    'asEast',
    'asSoutheast',
    'ca',
    'af',
    'afNorth',
    'afSouth',
    'oc',
    'sa',
    'caSa',
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};
