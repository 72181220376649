export const times = [
  {
    title: '2017',
    subtitle: 'First version of DVC was created and open sourced. First users.',
  },
  { title: '2018', subtitle: 'Iterative, Inc was incorporated. First hires.' },
  {
    title: '2019',
    subtitle:
      'More development on DVC. Adoption of the tool significantly increased.',
  },
  {
    title: '2020',
    subtitle: 'DVC 1.0 was released. New product CML was released.',
  },
  {
    title: '2021',
    subtitle: 'Studio product release. First enterprise customers.',
  },
];

export const defaultDeveloperData = {
  latitude: 0,
  longitude: 0,
  markerOffsetX: 0,
  markerOffsetY: 0,
  title: '',
  job: '',
  location: '',
  timezone: '',
  text: '',
  image: {
    childImageSharp: {
      gatsbyImageData: {},
    },
  },
  globeLink: 'about:blank',
  githubLink: 'about:blank',
  twitterLink: 'about:blank',
  linkedinLink: 'about:blank',
};
