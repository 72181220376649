// extracted by mini-css-extract-plugin
export var developercard = "DeveloperCard-module--developercard--RqkMA";
export var developercard__backBtn = "DeveloperCard-module--developercard__backBtn--Hzv9s";
export var developercard__backBtn__text = "DeveloperCard-module--developercard__backBtn__text---Pc2v";
export var developercard__closeBtn = "DeveloperCard-module--developercard__closeBtn--84D4O";
export var developercard__visuallyHiddenEl = "DeveloperCard-module--developercard__visuallyHiddenEl--42Fmg";
export var developercard__container = "DeveloperCard-module--developercard__container--jU17I";
export var developercard__textContainer = "DeveloperCard-module--developercard__textContainer---ObxU";
export var developercard__title = "DeveloperCard-module--developercard__title--1VVPc";
export var developercard__job = "DeveloperCard-module--developercard__job--Lofbq";
export var developercard__locationContainer = "DeveloperCard-module--developercard__locationContainer--ltKux";
export var developercard__location = "DeveloperCard-module--developercard__location--TiIjR";
export var developercard__avatar = "DeveloperCard-module--developercard__avatar--ISulk";
export var developercard__line = "DeveloperCard-module--developercard__line--JerMU";
export var developercard__text = "DeveloperCard-module--developercard__text--9oe2F";
export var developercard__iconContainer = "DeveloperCard-module--developercard__iconContainer--NaNt8";
export var developercard__icon = "DeveloperCard-module--developercard__icon--ibSxa";