import React from 'react';
import PropTypes from 'prop-types';

export default function Image({ gatsbyImageData, alt, className }) {
  const webpImageData = gatsbyImageData?.images?.sources[0];
  const defaultImgData = gatsbyImageData?.images?.fallback;
  return (
    <picture>
      <source
        srcSet={webpImageData.srcSet}
        type={webpImageData.type}
        sizes={webpImageData.sizes}
      />
      <source
        srcSet={defaultImgData.srcSet}
        type={`image/${
          defaultImgData.src.toLowerCase().endsWith('png') ? 'png' : 'jpeg'
        }`}
      />
      <img
        className={className}
        width={gatsbyImageData.width}
        height={gatsbyImageData.height}
        src={defaultImgData.src}
        alt={alt}
      />
    </picture>
  );
}

Image.propTypes = {
  gatsbyImageData: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};
