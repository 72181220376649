import React from 'react';
import PropTypes from 'prop-types';

import Popup from '../Popup/Popup';
import Icon from '../Icon/Icon';
import Avatar from '../Avatar/Avatar';
import locationIcon from '../../images/icn-location-gray.svg';
import globeIcon from '../../images/icn-globe-gray.svg';
import twitterIcon from '../../images/icn-twitter-gray.svg';
import linkedinIcon from '../../images/icn-linkedin-gray.svg';
import githubIcon from '../../images/icn-github-gray.svg';
import closeIcon from '../../images/icn-close.svg';
import backIcon from '../../images/icn-chevron-left-blue.svg';
import * as styles from './DeveloperCard.module.scss';

export function PresentationalDeveloperCard({
  title,
  job,
  location,
  text,
  image,
  globeLink,
  twitterLink,
  linkedinLink,
  githubLink,
  onBack,
  close,
}) {
  return (
    <div className={styles.developercard} tabIndex={0}>
      <button className={styles.developercard__closeBtn} onClick={close}>
        <Icon icon={closeIcon} widthPixelSizes={[16, 16, 16]} />
        <span className={styles.developercard__visuallyHiddenEl}>
          Close Popup
        </span>
      </button>
      <button
        className={styles.developercard__backBtn}
        onClick={() => {
          onBack();
          close();
        }}
      >
        <Icon icon={backIcon} widthPixelSizes={[7.5, 10, 10]} />
        <div className={styles.developercard__backBtn__text}>Back</div>
      </button>
      <div className={styles.developercard__container}>
        <div className={styles.developercard__textContainer}>
          <h4 className={styles.developercard__title}>{title}</h4>
          <p className={styles.developercard__job}>{job}</p>
          <div className={styles.developercard__locationContainer}>
            <Icon icon={locationIcon} widthPixelSizes={[16, 16, 16]} />
            <p className={styles.developercard__location}>{location}</p>
          </div>
        </div>
        <div className={styles.developercard__avatar}>
          <Avatar image={image} shape="square" id="popup" />
        </div>
      </div>
      <div className={styles.developercard__line}></div>
      <p className={styles.developercard__text}>{text}</p>
      <ul className={styles.developercard__iconContainer}>
        {globeLink ? (
          <li className={styles.developercard__icon}>
            <Icon
              icon={globeIcon}
              link={globeLink}
              linkDescription="Go to personal page"
              widthPixelSizes={[16, 16, 16]}
              background="light"
            />
          </li>
        ) : (
          ''
        )}
        {twitterLink ? (
          <li>
            <Icon
              icon={twitterIcon}
              link={twitterLink}
              linkDescription="Go to Twitter"
              widthPixelSizes={[16, 16, 16]}
              background="light"
            />
          </li>
        ) : (
          ''
        )}
        {linkedinLink ? (
          <li>
            <Icon
              icon={linkedinIcon}
              link={linkedinLink}
              linkDescription="Go to LinkedIn"
              widthPixelSizes={[16, 16, 16]}
              background="light"
            />
          </li>
        ) : (
          ''
        )}
        {githubLink ? (
          <li>
            <Icon
              icon={githubIcon}
              link={githubLink}
              linkDescription="Go to GitHub"
              widthPixelSizes={[16, 16, 16]}
              background="light"
            />
          </li>
        ) : (
          ''
        )}
      </ul>
    </div>
  );
}

export default function DeveloperCard(props) {
  return (
    <Popup
      isVisible={props.isOpen}
      onClose={props.onClose}
      ariaLabel={`Info About ${props.title}`}
      getContents={(close) => (
        <PresentationalDeveloperCard {...props} close={close} />
      )}
    />
  );
}

DeveloperCard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  globeLink: PropTypes.string,
  twitterLink: PropTypes.string,
  linkedinLink: PropTypes.string,
  githubLink: PropTypes.string,
};
