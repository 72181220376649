export function sortMembersByRegion(members) {
  const regions = {
    ak: [],
    gl: [],
    can: [],
    canEast: [],
    canWest: [],
    us: [],
    usWest: [],
    usEast: [],
    eu: [],
    euWest: [],
    euEast: [],
    as: [],
    asNorth: [],
    asWest: [],
    asSouth: [],
    asEast: [],
    asSoutheast: [],
    ca: [],
    caSa: [],
    sa: [],
    saWest: [],
    saEast: [],
    af: [],
    afNorth: [],
    afSouth: [],
    oc: [],
  };
  const restOfMembers = [];
  members.forEach((member) => {
    member.regions.forEach((region) => {
      if (regions[region]) {
        regions[region].push(member);
      } else {
        restOfMembers.push(member);
      }
    });
  });
  return { regions, restOfMembers };
}
